import dayjs from 'dayjs';

const eventRanges = {
  blackFriday: {
    end: dayjs('2024-12-08 23:59:59'),
    start: dayjs('2024-11-01 00:00:00'),
  },
  christmas: {
    end: dayjs('2024-12-26 23:59:59'),
    start: dayjs('2024-12-04 00:00:00'),
  },
};

const isEvent = (eventName, now) => {
  const { start, end } = eventRanges[eventName];

  return now.isAfter(start) && now.isBefore(end);
};

export const isChristmas = (now) => isEvent('christmas', dayjs(now));

export const isBlackFriday = (now) => isEvent('blackFriday', dayjs(now));
