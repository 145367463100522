/* eslint-disable complexity, sonarjs/cognitive-complexity, fp/no-this */
import dayjs from 'dayjs';
import Cookies from 'react-cookies';
import { PAGE_TYPES } from '../config/constants/page-types';
import COOKIES from '../config/cookies/cookies';
import { COOKIE_SUBSCRIBED_EXPIRES } from '../config/setup/setup';
import { isLightPage } from './browser';
import commonCookiesOptions from './commonCookiesOptions';
import { getPageType, parseWowcherPath, stripOrigin } from './url';

const activitiesList = [
  'paginationTest',
  'cloudinaryTest',
  'videoRollout',
  'onSiteV3',
  'dynamicPricing',
];

export const setCookie = ({
  key,
  value,
  expire,
  expireInHours = null,
  options = null,
}) => {
  let cookieExpire;

  // Set cookie expiration for days if `expire` is provided
  if (expire) {
    cookieExpire = dayjs().add(expire, 'days').toDate();
  }
  // Set cookie expiration for hours if `expireInHours` is provided
  else if (expireInHours) {
    cookieExpire = dayjs().add(expireInHours, 'hour').toDate();
  }

  Cookies.save(key, value, {
    ...(options ?? commonCookiesOptions),
    expires: cookieExpire ? new Date(cookieExpire) : null,
  });
};

export const removeCookie = (value) => {
  Cookies.remove(value, {
    domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
    path: '/',
  });
};

const expireAllCookies = (name, paths) => {
  const expires = new Date(0).toUTCString();

  // expire null-path cookies as well
  document.cookie = name + '=; expires=' + expires;

  for (let index = 0, leng = paths.length; index < leng; index++) {
    document.cookie = name + '=; path=' + paths[index] + '; expires=' + expires;
  }
};

// Cookies set from outside, by DTM or digioh
export const removeNonReactCookie = (value) => {
  expireAllCookies(value, ['/', '/deals/', '/deal/']);
};

const DtmActivityHelper = {
  filterByActionType(offers, filter) {
    let filteredOffers = [];
    if (Array.isArray(offers)) {
      filteredOffers = offers.filter((offer) => offer.action === filter);
    }

    return filteredOffers;
  },

  findByName(contentList, filterName) {
    let filteredOffers = [];
    if (Array.isArray(contentList)) {
      filteredOffers = contentList.find(
        (content) => content.name === filterName,
      );
    }

    return filteredOffers;
  },

  getActivityContentListByType(offers, activityType) {
    let activityContentList = [];
    if (
      Array.isArray(offers) &&
      offers.length > 0 &&
      typeof activityType === 'string'
    ) {
      const filteredOffers = this.filterByActionType(offers, activityType);
      if (
        Array.isArray(filteredOffers) &&
        filteredOffers.length > 0 &&
        Array.isArray(filteredOffers[0].content)
      ) {
        activityContentList = filteredOffers[0].content;
      }
    }

    return activityContentList;
  },

  getValueForActivity(activityContent, activityName) {
    let activityValue;
    const activityInfo = this.findByName(activityContent, activityName);
    if (
      activityInfo &&
      typeof activityInfo.group !== 'undefined' &&
      activityInfo.group !== null
    ) {
      activityValue = activityInfo.group;
    }

    return activityValue;
  },
};

export const isOfflineLightbox = (router) => {
  const [pathString, queryString] = router.asPath.split(/\?|#/g);
  const pathArray = pathString.split('/').filter((item) => item.length);
  const searchParameters = new URLSearchParams(queryString);
  const pageType = getPageType(pathArray, searchParameters);
  const { ito } = router.query;

  // We don't show lightbox if we have these cookies:
  if (isLightPage() || Cookies.load(COOKIES.lbSessionSkip)) return false;

  // We don't show lightbox for some pages
  if (
    pageType === PAGE_TYPES.guide ||
    pageType === PAGE_TYPES.ppc ||
    pageType === PAGE_TYPES.root ||
    router.asPath.includes('wowcher-dream-job') ||
    router.asPath.includes('email-deals') ||
    router.asPath.includes('refer-a-friend') ||
    router.asPath.includes('cheap-kids-toys') ||
    router.asPath.includes('internetsafety') ||
    ito?.includes('social_dyn_ret') ||
    ito?.includes('-dyn-ret-') ||
    ito?.includes('wowcher_retarget_display') ||
    ito?.includes('criteo_all') ||
    ito?.includes('user_browse') ||
    ito?.includes('abandon') ||
    ito?.includes('wowcher_retarget_display') ||
    ito?.includes('wowcher_email') ||
    ito?.includes('wowcher_affiliate_')
  )
    return false;

  return true;
};

export const cookieMonsterInit = () => {
  const url = stripOrigin(window.location.href);
  const { searchParams, details } = parseWowcherPath(url);

  const ito = searchParams.get('ito');
  const gclid = searchParams.get('gclid');
  const cauth = searchParams.get('cauth');
  const mgid = searchParams.get('mgid');
  const msclkid = searchParams.get('msclkid');
  const source = searchParams.get('source');
  const cjevent = searchParams.get('cjevent');
  const dealId = details.dealId;

  // add usersource from url query string if one is present
  if (ito?.length > 0) {
    setCookie({
      expire: ito.includes('_affiliate') ? 30 : 365,
      key: COOKIES.userSource,
      value: ito,
    });
    setCookie({
      expire: null,
      key: COOKIES.sessionSource,
      value: dealId ? `${ito}_${dealId}` : ito,
    });
  }

  // detects and sets brandcookie eg 'wowcher-win || living-social-win' a value depending on the users entry to app
  const brandWin = Cookies.load(COOKIES.brandCookie);
  if (
    brandWin &&
    brandWin !== 'registered_user' &&
    brandWin !== 'subscribed' &&
    ito
  ) {
    if (ito.includes('wowcher_email')) {
      setCookie({
        expire: 30,
        key: COOKIES.brandCookie,
        value: 'wowcher_email',
      });
    } else if (
      ito.includes('criteo_all-user') &&
      brandWin !== 'bypassed' &&
      brandWin !== 'wowcher_email'
    ) {
      setCookie({
        expire: 1,
        key: COOKIES.brandCookie,
        value: 'wowcher_criteo',
      });
    } else if (
      ito.indexOf('wowcher_cloudiq') &&
      brandWin !== 'bypassed' &&
      brandWin !== 'wowcher_email'
    ) {
      setCookie({
        expire: 1,
        key: COOKIES.brandCookie,
        value: 'wowcher_cloudiq',
      });
    }
  }

  // Checks for the tms visitor ID and sets as a cookie
  const tmsID = Cookies.load(COOKIES.tmsID);
  if (tmsID) {
    // Remove the cookie set by DTM to avoid redudant cookies
    removeCookie(COOKIES.tmsID);

    setCookie({
      expire: 1,
      key: COOKIES.tmsID,
      value: tmsID,
    });
  }

  // Checks for freshRel being set by DTM and sets as a cookie
  const freshRel = Cookies.load(COOKIES.freshRel);
  if (freshRel) {
    // Remove the cookie set by DTM to avoid redudant cookies
    removeCookie(COOKIES.freshRel);

    setCookie({
      expire: 1,
      key: COOKIES.freshRel,
      value: freshRel,
    });
  }
  // set gclid cookie if available from url path
  if (gclid?.length > 0) {
    setCookie({ expire: 365, key: COOKIES.gclid, value: gclid });
  }

  if (ito?.indexOf('_affiliate') > -1 && cjevent?.length > 0) {
    setCookie({
      expire: 30,
      key: COOKIES.cjevent,
      options: {
        setDate: dayjs().toISOString(),
      },
      value: cjevent,
    });
  }

  // setting 30 day awinSource and Awin_event cookies in parallel
  if (ito?.indexOf('_affiliate') > -1 && source === 'awin') {
    // used to bypass lightbox activation
    setCookie({ key: COOKIES.awinSource, value: 'awin' });

    const cookieObject = {
      setDate: dayjs().toISOString(),
      value: 'awin',
    };

    setCookie({ expire: 30, key: COOKIES.awin_event, value: cookieObject });
  }

  // check customer came from a wowcher/livingsocial email link, set ct cookie from link if not already set
  if (ito?.indexOf('_email_') > -1) {
    if (!cauth) return;

    const cookieValue = Cookies.load(COOKIES.customerToken);
    if (!cookieValue || cookieValue.toLowerCase() === 'null') {
      setCookie({ key: COOKIES.customerToken, value: cauth });
    }
  }

  // Setting msclkid cookie based on URL msclkid param
  if (msclkid?.length > 0) {
    setCookie({ expire: 365, key: COOKIES.msclkid, value: msclkid });
  }

  if (mgid?.length > 0) {
    setCookie({ expire: 1, key: COOKIES.mgid, value: mgid });
  }

  if (
    brandWin &&
    (brandWin === 'registered_user' || brandWin === 'subscribed')
  ) {
    setCookie({
      expire: COOKIE_SUBSCRIBED_EXPIRES,
      key: COOKIES.brandCookie,
      value: brandWin,
    });
  }
  window.adobe.target.getOffer({
    error: (error) => {
      console.error('window.adobe.target.getOffer:', error);
    },
    mbox: 'target-global-mbox',
    params: { specificActivity: activitiesList.join(',') },
    //
    success: (offers) => {
      const activityContent = DtmActivityHelper.getActivityContentListByType(
        offers,
        'setJson',
      );

      // Pagination test cookie
      if (activityContent && activityContent.length > 0) {
        if (!Cookies.load('pagination')) {
          const paginationActivityValue = DtmActivityHelper.getValueForActivity(
            activityContent,
            'paginationTest',
          );
          if (
            typeof paginationActivityValue === 'string' &&
            paginationActivityValue.trim().length > 0
          ) {
            setCookie({
              expire: 365,
              key: COOKIES.pagination,
              value: paginationActivityValue,
            });
          }
        }

        // Video test
        const videoActivityValue = DtmActivityHelper.getValueForActivity(
          activityContent,
          'videoRollout',
        );
        if (
          typeof videoActivityValue === 'string' &&
          videoActivityValue.trim().length > 0
        ) {
          setCookie({
            expire: 365,
            key: COOKIES.videoRolloutTest,
            value: videoActivityValue,
          });
        }

        // onSiteV3 test
        const onSiteVActivityValue = DtmActivityHelper.getValueForActivity(
          activityContent,
          'onSiteV3',
        );
        if (
          typeof onSiteVActivityValue === 'string' &&
          onSiteVActivityValue.trim().length > 0
        ) {
          setCookie({
            expire: 365,
            key: COOKIES.onSiteV3,
            value: onSiteVActivityValue,
          });
        }
      }
    },
  });
};

// FB Tracking Update
export const cookiefbInit = (path) => {
  const urlParameters = path ? new URLSearchParams(path) : {};
  const fbc = urlParameters?.get('fbclid');
  const expires = new Date();
  expires.setDate(expires.getDate() + 90);

  if (fbc) {
    Cookies.save(COOKIES.fbc, fbc, {
      ...commonCookiesOptions,
      expires,
    });
  }
};

export const cookieItoInit = (dealId = null) => {
  const url = stripOrigin(window.location.href);
  const { searchParams } = parseWowcherPath(url);
  const ito = searchParams.get('ito');

  // Setting dod-ito-source cookie based on URL ito param and dealID
  // ITR-695: we want to be sure that the users get the same price
  // that they saw on google ads.
  if (ito?.length > 0) {
    const isSetIto =
      !ito.includes('wowcher_email') ||
      (ito.includes('_withprices_') && ito.includes('wowcher_email'));

    if (isSetIto && dealId) {
      if (Cookies.load(COOKIES.sessionITOSource)) {
        removeCookie(COOKIES.sessionITOSource);
      }

      if (Cookies.load(COOKIES.historicPurchaseSource)) {
        removeCookie(COOKIES.historicPurchaseSource);
      }

      Cookies.save(COOKIES.sessionITOSource, dealId, {
        ...commonCookiesOptions,
        expires: 0,
      });

      Cookies.save(COOKIES.historicPurchaseSource, ito, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });
    }
  }
};

export const setPostLoginUrl = (routerQuery = '/') => {
  const urlNoQuerystring = routerQuery.split('?')[0];
  const fullURL =
    window.location.protocol + '//' + window.location.host + urlNoQuerystring;

  setCookie({ expire: 30, key: COOKIES.postLoginUrl, value: fullURL });
};

export const setPostQueryLoginUrl = (routerQuery = '/') => {
  const fullURL =
    window.location.protocol + '//' + window.location.host + routerQuery;

  setCookie({ expire: 30, key: COOKIES.postLoginUrl, value: fullURL });
};

export const setLoginSourcePageType = (pagetype = '/') => {
  setCookie({ expire: 30, key: COOKIES.loginSourcePageType, value: pagetype });
};

export const setEmailCookie = (email) => {
  if (email) {
    setCookie({ expire: 1, key: COOKIES.deleteEmail, value: email });
  }
};

export const setSubscribedBrandWinCookie = () => {
  if (Cookies.load(COOKIES.brandCookie) !== 'registered_user') {
    setCookie({
      expire: COOKIE_SUBSCRIBED_EXPIRES,
      key: COOKIES.brandCookie,
      value: 'subscribed',
    });
  }
};

export const showNationalReviews = () => {
  return Cookies.load(COOKIES.reviewsTest) === 'b' || isLightPage();
};

export const setDPCookie = () => {
  const expires = new Date(new Date().setUTCHours(23, 59, 59, 0));

  const value = String.fromCharCode(97 + Math.floor(Math.random() * 3));

  Cookies.save(COOKIES.dynamicPrice, value, {
    ...commonCookiesOptions,
    expires,
  });
};
