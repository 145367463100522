export const SPLAT = {
  'living-social':
    'https://resources.wowcher.co.uk/images/ls/LS-Blobs-yellow.png',
  wowcher: 'https://resources.wowcher.co.uk/images/splat.svg',
};

export const VIP_SPLAT = 'https://resources.wowcher.co.uk/images/splatVIP.svg';

export const GOOGLE_IMG =
  'https://resources.wowcher.co.uk/assets/img/btn_google_dark_normal_ios.svg';
export const VIP_IMG = {
  livingsocial:
    'https://resources.wowcher.co.uk/assets/img/livingsocial_vip.svg',
  livingsocialie:
    'https://resources.wowcher.co.uk/assets/img/livingsocial_vip.svg',
  wowcher: 'https://resources.wowcher.co.uk/assets/img/wowcher_vip.svg',
  wowcherie: 'https://resources.wowcher.co.uk/assets/img/wowcher_vip.svg',
};
export const MYACCOUNT_LOGO_MOBILE = {
  livingsocial:
    'https://resources.wowcher.co.uk/assets/img/livingsocial/livingsocial-mobile-burger-menu.png',
  livingsocialie:
    'https://resources.wowcher.co.uk/assets/img/livingsocial/livingsocial-mobile-burger-menu.png',
  wowcher: 'https://resources.wowcher.co.uk/assets/img/mobile-menu-button.png',
  wowcherie:
    'https://resources.wowcher.co.uk/assets/img/mobile-menu-button.png',
};
export const MYACCOUNT_LOGO_NORTON = '/assets/img/norton_seal.png';
export const APPLE_APP_STORE_LOGO =
  'https://resources.wowcher.co.uk/images/apple_appstore_big.svg';
export const GOOGLE_PLAY_LOGO =
  'https://resources.wowcher.co.uk/images/google-play-badge.svg';
export const CAREERS_IMG = 'https://resources.wowcher.co.uk/images/careers.jpg';

export const FAVICON = {
  'living-social':
    'https://resources.wowcher.co.uk/images/favicon-living-social.ico?v=101',
  wowcher: 'https://resources.wowcher.co.uk/images/favicon-wowcher.ico?v=101',
};
export const PLACEHOLDER_IMG = {
  livingsocial: 'https://resources.wowcher.co.uk/images/ls/not-found.jpg',
  livingsocialie: 'https://resources.wowcher.co.uk/images/ls/not-found.jpg',
  wowcher: 'https://resources.wowcher.co.uk/images/not-found.jpg',
  wowcherie: 'https://resources.wowcher.co.uk/images/not-found.jpg',
};
export const PLACEHOLDER_IMG_ALT = 'No image found';

export const CLAIM_GIFT_IMG =
  'https://resources.wowcher.co.uk/assets/img/gift-redeem.png';

export const NORTON_SEAL =
  'https://static.wowcher.co.uk/binaries/godaddy-ssl.png';

export const BACK_LOGO = {
  livingsocial:
    'https://resources.wowcher.co.uk/images/ls/livingsocial-back.png',
  livingsocialie:
    'https://resources.wowcher.co.uk/images/ls/livingsocial-back.png',
  wowcher: 'https://resources.wowcher.co.uk/assets/img/wowcher-back.png',
  wowcherie: 'https://resources.wowcher.co.uk/assets/img/wowcher-back.png',
};

export const CYF_BANNER = {
  brands: {
    livingSocial:
      'https://images.wowcher.co.uk/binaries/choose-your-flight-desktop-2-living-social.jpg',
    vip:
      'https://images.wowcher.co.uk/binaries/choose-your-flight-desktop-2-wowcher.jpg',
    wowcher:
      'https://images.wowcher.co.uk/binaries/choose-your-flight-desktop-2-wowcher.jpg',
  },
};

export const CYF_BANNER_MOBILE = {
  brands: {
    livingSocial:
      'https://images.wowcher.co.uk/binaries/choose-your-flight-mobile-2-living-social.jpg',
    vip:
      'https://images.wowcher.co.uk/binaries/choose-your-flight-mobile-2-wowcher.jpg',
    wowcher:
      'https://images.wowcher.co.uk/binaries/choose-your-flight-mobile-2-wowcher.jpg',
  },
};

// APP BANNER
export const APP_BANNER_ICON = {
  livingsocial:
    'https://resources.wowcher.co.uk/images/ls/Icon-App-60x60@1x.png',
  livingsocialie:
    'https://resources.wowcher.co.uk/images/ls/Icon-App-60x60@1x.png',
  wowcher: 'https://resources.wowcher.co.uk/images/Icon-App-60x60@1x.png',
  wowcherie: 'https://resources.wowcher.co.uk/images/Icon-App-60x60@1x.png',
};

export const GIFT_CARD_IMG = {
  livingsocial:
    'https://resources.wowcher.co.uk/assets/img/livingsocial/ls-gift-card.png',
  livingsocialie:
    'https://resources.wowcher.co.uk/assets/img/livingsocial/ls-gift-card.png',
  wowcher: 'https://resources.wowcher.co.uk/assets/img/gift-card.png',
  wowcherie: 'https://resources.wowcher.co.uk/assets/img/gift-card.png',
};

export const GIFTING_IMG =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/Gift-pack-pop-up.svg';
export const GIFTING_IMG_MOB =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/Gift-pack-pop-up-mobile.svg';
export const GIFTING_WRAP_IMG =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/Gift-wrap-pop-up.svg';
export const GIFTING_WRAP_IMG_MOB =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/Gift-wrap-pop-up-mobile.svg';

export const GIFT_ICON_IMG = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/Icons/LS-Explained-Gift-Icon.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/present-icon.svg',
};

export const SUBSCRIBE_IMG =
  'https://resources.wowcher.co.uk/images/subscription-bg_m.jpg';

// WALLET
export const WALLET_HISTORY_HEADER =
  'https://resources.wowcher.co.uk/assets/img/wallet-top.png';
export const WALLET_HISTORY_CHIP =
  'https://resources.wowcher.co.uk/assets/img/chip.png';

// CHECKOUT
export const GIFT_PACK_INFO_IMG = {
  livingsocial:
    'https://resources.wowcher.co.uk/assets/img/Christmas24-LS-Gift-Pack-lead.jpg',
  livingsocialie:
    'https://resources.wowcher.co.uk/assets/img/Christmas24-LS-Gift-Pack-lead.jpg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/Christmas-Wow-Gift-Pack-Flat-Lay.jpg',
  wowcherie:
    'https://resources.wowcher.co.uk/assets/img/Christmas-Wow-Gift-Pack-Flat-Lay.jpg',
};

export const GIFT_WRAP_INFO_IMG =
  'https://resources.wowcher.co.uk/assets/img/giftwrap-packaging.jpg';
export const EGIFT_INFO_IMG = {
  livingsocial:
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/gift/ls_gifting.jpg',
  livingsocialie:
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/gift/ls_gifting.jpg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/eGift-modal-info.jpg',
  wowcherie:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/eGift-modal-info.jpg',
};

export const WALLET_IMG = {
  livingsocial:
    'https://resources.wowcher.co.uk/assets/img/livingsocial/livingsocial-wallet.png',
  livingsocialie:
    'https://resources.wowcher.co.uk/assets/img/livingsocial/livingsocial-wallet.png',
  wowcher: 'https://resources.wowcher.co.uk/assets/img/wowcher-wallet.png',
  wowcherie: 'https://resources.wowcher.co.uk/assets/img/wowcher-wallet.png',
};
export const CARD_BUTTON_IMG =
  'https://resources.wowcher.co.uk/assets/img/card-pay-button.svg';
export const PAYPAL_BUTTON_IMG =
  'https://resources.wowcher.co.uk/assets/img/paypal-button.svg';
export const GOOGLE_BUTTON_IMG_MARK =
  'https://resources.wowcher.co.uk/assets/img/GPay_Acceptance_Mark_800.png';
export const GOOGLE_BUTTON_IMG =
  'https://resources.wowcher.co.uk/assets/img/google-pay-button.svg';
export const APPLE_BUTTON_IMG =
  'https://resources.wowcher.co.uk/assets/img/apple-pay-button.svg';
export const BUTTON_IMG_GOOGLE_PAY =
  'https://resources.wowcher.co.uk/assets/img/google-pay-button-white.svg';
export const BUTTON_IMG_APPLE_PAY =
  'https://resources.wowcher.co.uk/assets/img/apple-pay-button-white.svg';
export const CLEARPAY_BUTTON_IMG =
  'https://resources.wowcher.co.uk/assets/img/clearpay-badge.svg';
export const CLEARPAY_MARKETING_BADGE =
  'https://resources.wowcher.co.uk/assets/img/clearpay-badge.svg';

export const KLARNA_BUTTON_IMG =
  'https://resources.wowcher.co.uk/assets/img/klarna.svg';
export const KLARNA_LOGO =
  'https://resources.wowcher.co.uk/assets/img/klarna-modal-title.svg';
export const KLARNA_MARKETING_BADGE =
  'https://resources.wowcher.co.uk/assets/img/klarna-marketing-badge.svg';

export const ATOL_LOGO =
  'https://static.wowcher.co.uk/images/international-travel/atol-logo.svg';

// VIP
export const CROWN_ICON = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/livingsocial-crown01.svg',
  wowcher: 'https://resources.wowcher.co.uk/assets/img/wowcher-crown01.svg',
};

export const TAG_ICON = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/livingsocial-tag01.svg',
  wowcher: 'https://resources.wowcher.co.uk/assets/img/wowcher-tag01.svg',
};
export const TROPHY_ICON = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/livingsocial-trophy01.svg',
  wowcher: 'https://resources.wowcher.co.uk/assets/img/wowcher-trophy01.svg',
};

export const PAYPAL_CREDIT =
  'https://resources.wowcher.co.uk/images/paypal-credit-logo.svg';
export const IMG_INVASIVEHEALTH_THEREISNOHURRY = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/livingsocial-wellbeing-calendar.jpg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/wowcher-wellbeing-calendar.jpg',
};
export const IMG_INVASIVEHEALTH_TIMETOTHINK = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/livingsocial-wellbeing-notsure.jpg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/wowcher-wellbeing-notsure.jpg',
};
export const IMG_INVASIVEHEALTH_SAFEHANDS = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/livingsocial-wellbeing-safehands.jpg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/wowcher-wellbeing-safehands.jpg',
};
export const IMG_INVASIVEHEALTH_NOTSURE = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/livingsocial-wellbeing-timetothink.jpg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/wowcher-wellbeing-timetothink.jpg',
};

export const OPEN_POP_UP_IMAGE =
  'https://resources.wowcher.co.uk/assets/img/open-pop-up.svg';
export const LIGHT_BOX_MODAL = {
  brands: {
    livingSocial: {
      background: {
        popeye:
          'https://resources.wowcher.co.uk/images/ls/subscription-bg-v2.jpg',
        subscribe: SUBSCRIBE_IMG,
      },
    },
    vip: {
      background: {
        popeye:
          'https://resources.wowcher.co.uk/images/subscriptionImage_t3-v2.jpg',
        subscribe: SUBSCRIBE_IMG,
      },
    },
    wowcher: {
      background: {
        popeye: 'https://resources.wowcher.co.uk/images/subscription-bg-v2.jpg',
        subscribe: SUBSCRIBE_IMG,
      },
    },
  },
};

const ERROR_404_PLACEHOLDER =
  'https://resources.wowcher.co.uk/images/404-bg.png';
export const ERROR_TYPES = {
  brands: {
    livingSocial: {
      404: ERROR_404_PLACEHOLDER,
    },
    vip: {
      404: ERROR_404_PLACEHOLDER,
    },
    wowcher: {
      404: ERROR_404_PLACEHOLDER,
    },
  },
};

export const FLAGS = {
  ireland: 'https://resources.wowcher.co.uk/images/ls/IE_flag.jpg',
  unitedKingdom: 'https://resources.wowcher.co.uk/images/ls/UK_flag.jpg',
};

export const TRIP_ADVISOR =
  'https://resources.wowcher.co.uk/images/trip-advisor-logo.png';
export const REVIEWS_IO =
  'https://resources.wowcher.co.uk/assets/img/reviews-io.svg';

const BRAND_URL = 'https://resources.wowcher.co.uk/assets/img/brand';

export const GIFT_RECIPIENT_ONE = {
  'living-social': `${BRAND_URL}/living-social/gift/hubby_v2.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/hubby_v2.svg`,
};
export const GIFT_RECIPIENT_TWO = {
  'living-social': `${BRAND_URL}/living-social/gift/wifey_v2.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/wifey_v2.svg`,
};
export const GIFT_RECIPIENT_THREE = {
  'living-social': `${BRAND_URL}/living-social/gift/little-ones_v2.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/little-ones_v2.svg`,
};
export const GIFT_RECIPIENT_FOUR = {
  'living-social': `${BRAND_URL}/living-social/gift/teen_v2.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/teen_v2.svg`,
};
export const GIFT_RECIPIENT_FIVE = {
  'living-social': `${BRAND_URL}/living-social/gift/bff_v2.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/bff_v2.svg`,
};
export const GIFT_RECIPIENT_SIX = {
  'living-social': `${BRAND_URL}/living-social/gift/colleague_v2.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/colleague_v2.svg`,
};
export const GIFT_RECIPIENT_SEVEN = {
  'living-social': `${BRAND_URL}/living-social/gift/mum_v3.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/mum_v3.svg`,
};
export const GIFT_RECIPIENT_EIGHT = {
  'living-social': `${BRAND_URL}/living-social/gift/dad_v2.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/dad_v2.svg`,
};
export const GIFT_RECIPIENT_NINE = {
  'living-social': `${BRAND_URL}/living-social/gift/grandparents.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/grandparents.svg`,
};
export const GIFT_RECIPIENT_TEN = {
  'living-social': `${BRAND_URL}/living-social/gift/valentine_v3.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/valentine_v3.svg`,
};

export const GIFT_PERSONALITY_ONE = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/playful.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/personality/playful.svg`,
};
export const GIFT_PERSONALITY_TWO = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/instafamous.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/personality/instafamous.svg`,
};
export const GIFT_PERSONALITY_THREE = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/geeky.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/personality/geeky.svg`,
};
export const GIFT_PERSONALITY_FOUR = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/giggly_v2.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/personality/giggly_v2.svg`,
};
export const GIFT_PERSONALITY_FIVE = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/gorgeous.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/personality/gorgeous.svg`,
};
export const GIFT_PERSONALITY_SIX = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/fit.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/personality/fit.svg`,
};
export const GIFT_PERSONALITY_SEVEN = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/pampered.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/personality/pampered.svg`,
};
export const GIFT_PERSONALITY_EIGHT = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/jetsetter.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/personality/jetsetter.svg`,
};
export const GIFT_PERSONALITY_NINE = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/daredevil.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/personality/daredevil.svg`,
};
export const GIFT_PERSONALITY_TEN = {
  'living-social': `${BRAND_URL}/living-social/gift/personality/indulgent.svg`,
  wowcher: `${BRAND_URL}/wowcher/gift/indulgent.svg`,
};

export const GIFT_BANNER = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/gift/banner/mothers-day-desktop_v1.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/gift/banner/mothers-day-desktop_v2.svg',
};
export const GIFT_BANNER_MOBILE = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/gift/banner/mothers-day-mobile_v1.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/gift/banner/mothers-day-mobile_v2.svg',
};
export const PAYPAL_CREDIT_OVERLAY =
  'https://static.wowcher.co.uk/binaries/Eligible-for-Paypal-Credit-New.png';

export const PAYPAL_CREDIT_OVERLAY_TRAVEL =
  'https://static.wowcher.co.uk/binaries/PayPal-and-Travel-Spacing-WOW.png';

// Redeem In App info

export const REDEEM_WOWCHER_INFO =
  'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-info-pop-v2.svg';

export const REDEEM_WOWCHER_INFO_MOBILE =
  'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-info-popup-mobile-v2.svg';

export const REDEEM_LS_INFO =
  'http://resources.wowcher.co.uk/assets/img/brand/living-social/redeem/Redeem-desktop.jpeg';

export const REDEEM_LS_INFO_MOBILE =
  'http://resources.wowcher.co.uk/assets/img/brand/living-social/redeem/Redeem-mobile.jpeg';

// QR codes for redeem in app

export const REDEEM_WOWCHER_QR_CODE_DEV =
  'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-in-app-dev-nxt.svg';

export const REDEEM_WOWCHER_QR_CODE_PROD =
  'https://resources.wowcher.co.uk/assets/img/wowcher-redeem-in-app-prod.svg';

export const REDEEM_LS_QR_CODE_DEV =
  'https://resources.wowcher.co.uk/assets/img/ls-redeem-in-app-dev-nxt.svg';

export const REDEEM_LS_QR_CODE_PROD =
  'https://resources.wowcher.co.uk/assets/img/ls-redeem-in-app-prod.svg';

export const PINK_GIFT =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/gift/banner/pink_gift.png';

export const GIFT_FINDER_BANNER_MOBILE =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/gift/banner/gifts-mobile-banner.png';

// Wowcher VIP Logo
export const VIP_LOGO = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/VIP/LivingSocial+VIP+Logo-you.png',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/vip/VIP+Logo.png',
};

export const VIP_BG =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/New+VIP+Pattern+Colourwash-bigger.svg';

export const VIP_INFO_BULLET =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/vip/VIP+Bullet+Point.png';

// Wowcher VIP HUB Exclusive
export const VIP_EXCLUSIVE_TAG = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/VIP/LS-VIP+Exclusive+Badge_LS.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/vip/VIP+Exclusive+Badge1_1.svg',
};

// 2nd Checkout Progress Icons

export const PINK_ORDER_CONFIRMED = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/second-checkout/LS-tick.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/Pink+tick.svg',
};

export const PINK_BENEFITS = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/second-checkout/LS-add.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/Pink+add.svg',
};

export const PINK_DEALS = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/second-checkout/LS-deals.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/Pink+deals.svg',
};

export const PINK_REDEEM_VOUCHERS = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/second-checkout/LS-white-icon.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/Pink+Wowcher.svg',
};

// 2nd checkout progress icons - new

export const ORDER_CONFIRMED_ACTIVE_ICON = {
  'living-social': 'https://resources.wowcher.co.uk/images/group_28.png',
  wowcher: 'https://resources.wowcher.co.uk/images/group_14.png',
};

export const ORDER_CONFIRMED_STEP_ICON = {
  'living-social': 'https://resources.wowcher.co.uk/images/group_14.png',
  wowcher: 'https://resources.wowcher.co.uk/images/group_14.png',
};

export const BENEFITS_ACTIVE_ICON = {
  'living-social': 'https://resources.wowcher.co.uk/images/group_22.png',
  wowcher: 'https://resources.wowcher.co.uk/images/group_12.png',
};

export const BENEFITS_STEP_ICON = {
  'living-social': 'https://resources.wowcher.co.uk/images/group_16.png',
  wowcher: 'https://resources.wowcher.co.uk/images/group_16.png',
};
export const DEALS_ACTIVE_ICON = {
  'living-social': 'https://resources.wowcher.co.uk/images/group_21.png',
  wowcher: 'https://resources.wowcher.co.uk/images/group_11.png',
};

export const DEALS_STEP_ICON = {
  'living-social': 'https://resources.wowcher.co.uk/images/group_15.png',
  wowcher: 'https://resources.wowcher.co.uk/images/group_15.png',
};
export const REDEEM_VOUCHER_ACTIVE_ICON = {
  'living-social': 'https://resources.wowcher.co.uk/images/Group_37.png',
  wowcher: 'https://resources.wowcher.co.uk/images/group_13.png',
};

export const REDEEM_VOUCHER_STEP_ICON = {
  'living-social': 'https://resources.wowcher.co.uk/images/group_17.png',
  wowcher: 'https://resources.wowcher.co.uk/images/group_17.png',
};

export const WHITE_BENEFITS = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/second-checkout/LS-white-add.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/White+add.svg',
};

export const WHITE_DEALS = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/second-checkout/LS-white-deals.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/White+deals.svg',
};

export const WHITE_REDEEM_VOUCHERS = {
  'living-social': 'https://resources.wowcher.co.uk/images/Group_39.png',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/White+Wowcher.svg',
};

// 2nd Checkout Vouhcer Benefit Card Icons
export const CHAMPAGNE =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/Champagne.svg';
export const GREEN_TICK =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/Green+tick.svg';
export const CLOCK = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/second-checkout/LS-clock.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/Clock.svg',
};
export const SHIELD = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/second-checkout/shield.svg',
  wowcher:
    'https://resources.wowcher.co.uk/assets/img/brand/wowcher/second-checkout/shield.svg',
};
export const DISABLED_SHIELD =
  'https://resources.wowcher.co.uk/assets/img/shield.svg';

export const EXCLAMATION_CIRCLE_RED =
  'https://resources.wowcher.co.uk/assets/img/ExclamationCircleRed.svg';

export const WOW =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/Wowcher-Wow-Pink-Splat.svg';

// Map View Pins Icons
export const MAP_WIDGET_BG =
  'http://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map+View+Small.svg';
export const MAP_MENU =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/menu.svg';
export const MAP =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/map.svg';
export const MAP_MARKER_OUTLINED =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/map-marker.svg';
export const MAP_MARKER_FILLED =
  'http://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/map-marker-2.svg';

export const ACTIVITIES =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map_+View_Activities.svg';
export const BEAUTY =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map_View_Beauty.svg';
export const UK_CITY_BREAKS =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map_View_UK-City-Breaks.svg';
export const SPORTS_ADVENTURE =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map_View_Sports-Adventure.svg';
export const SPAS_COUNTRY_HOUSE =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map_View_Spas-Country-House.svg';
export const RESTURANTS_BARS =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map_View_Restaurants-Bars.svg';
export const FITNESS =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map_View_Fitness.svg';
export const ENTERTAINMENT =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map_View_Entertainment.svg';
export const UK_SEASIDE =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/map-view/Map_View_UK-Seaside.svg';

export const GIFTING_DISCOUNT_IMAGE = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/Icons/LS-Discounts.svg',
  wowcher:
    'http://resources.wowcher.co.uk/assets/img/brand/wowcher/discounts.svg',
};

export const GIFTING_GIFTPACK_IMAGE = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/Icons/LS-Gift-Pack.png',
  wowcher:
    'http://resources.wowcher.co.uk/assets/img/brand/wowcher/giftpack_asset.png',
};

export const GIFTING_DELIVERY_IMAGE = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/Icons/LS-Delivery.svg',
  wowcher:
    'http://resources.wowcher.co.uk/assets/img/brand/wowcher/delivery.svg',
};

export const GIFTING_LOCAL_IMAGE = {
  'living-social':
    'https://resources.wowcher.co.uk/assets/img/brand/living-social/Icons/LS-Local-PDF.svg',
  wowcher:
    'http://resources.wowcher.co.uk/assets/img/brand/wowcher/local_pdf.svg',
};
// Refer A Friend For Only Wowcher
export const RAF_BANNER =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/refer-a-friend/raf_new_banner.png';
export const RAF_MOBILE_BANNER =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/refer-a-friend/raf_mobile_banner.png';
export const RAF_DOTTED_BOX =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/refer-a-friend/dotted_xbox.svg';
export const RAF_MONEY =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/refer-a-friend/Money-01.svg';
export const RAF_PLANE =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/refer-a-friend/Plane-01.svg';
export const REFEREE_WELCOME_BANNER =
  'https://resources.wowcher.co.uk/assets/img/brand/wowcher/refer-a-friend/raf_welcome_popup.jpg';
export const REFEREE_ALREADY_REGISTERED_BANNER =
  'https://resources.wowcher.co.uk/images/Woman1.jpg';
