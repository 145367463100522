import Cookies from 'react-cookies';
import axios from '../../components/_generic/axiosSplunk/axiosSplunk';
import { LOCATIONS } from '../../config/constants/action-types';
import COOKIES from '../../config/cookies/cookies';
import {
  DEFAULT_LOCATION,
  SITE_WOWCHERIE,
  URLLOCATIONS,
} from '../../config/setup/setup';
import { getAppPlatform } from '../../helpers/device';
import { parseWowcherPath } from '../../helpers/url';

const setLocationCookie = (locationObject) => {
  // only name, shortName needed in cookie
  const cookieLocationObject = {
    lat: locationObject?.latLon?.lat,
    lon: locationObject?.latLon?.lon,
    name: locationObject.name,
    shortName: locationObject.shortName,
  };
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 1);
  Cookies.save(COOKIES.location, cookieLocationObject, {
    domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
    expires,
    path: '/',
    samesite: 'none',
  });
};

export const setLocation = (location, overWriteCookie) => (dispatch) => {
  if (overWriteCookie) setLocationCookie(location);

  return dispatch({ location, type: LOCATIONS.SET_LOCATION });
};

export const getLocation = (locations, router) => async (dispatch) => {
  try {
    const locationCookie = Cookies.load(COOKIES.location);
    const isCookieValid = locationCookie && locationCookie !== 'undefined';

    let locationToDispatch = null;
    let shouldOverwriteCookie = false;

    if (isCookieValid) {
      // Find location matching the cookie
      locationToDispatch = locations.find(
        (loc) =>
          loc.shortName === locationCookie.shortName &&
          loc.countryCode === process.env.NEXT_PUBLIC_COUNTRY_CODE,
      );

      // Determine if cookie should be overwritten
      if (
        locationToDispatch &&
        !locationCookie?.lat &&
        locationToDispatch?.latLon?.lat &&
        locationToDispatch?.latLon?.lon
      ) {
        shouldOverwriteCookie = true;
      }
    }

    // Fallback if no valid cookie or matching location
    if (!locationToDispatch) {
      const { details } = parseWowcherPath(router.asPath);
      locationToDispatch = DEFAULT_LOCATION[process.env.NEXT_PUBLIC_SITE];

      if (details?.localDealLocation) {
        const fallbackLocation = locations.find(
          (loc) =>
            loc.shortName === details.localDealLocation &&
            loc.countryCode === process.env.NEXT_PUBLIC_COUNTRY_CODE,
        );
        if (fallbackLocation) {
          locationToDispatch = fallbackLocation;
        }
      }

      shouldOverwriteCookie = true; // Always overwrite in fallback scenario
    }

    if (locationToDispatch) {
      dispatch(setLocation(locationToDispatch, shouldOverwriteCookie));
    }
  } catch (error) {
    console.error('Error in getLocation:', error);
  }
};

export const setLocations = (locations) => (dispatch) => {
  return dispatch({ locations, type: LOCATIONS.SET_LOCATIONS });
};

export const getLocations = () => async (dispatch) => {
  try {
    const result = await axios(URLLOCATIONS, {
      headers: {
        'app-platform': getAppPlatform(),
        brand: process.env.NEXT_PUBLIC_BRAND,
        'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
        version: 'v1.1',
        webapp: true,
      },
      method: 'GET',
      withCredentials: true,
    });
    const locations = result.data || [];

    const filteredLocations = locations.filter(
      (item) => item.name !== 'Glasgow City',
    );

    dispatch(setLocations(filteredLocations));

    return locations;
  } catch {
    // TODO: Show error message if needed
    return [];
  }
};
